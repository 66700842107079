html, body {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-size: 75%;
  background: #f6f7f7;
}

html > body div.ui.bottom.right.popup {
  padding: 0px;
  border-radius: 0.66rem;
}

#root {
  min-height: 100%;
  position: relative;
  background: #f6f7f7;
  cursor: default;
}

html > body form.ui.form div.react-date-picker__button__input {
  padding-left: 0.33rem;
}

html > body form.ui.form div.react-date-picker__button__input input {
  border: none;
  padding: 0px;
}

html > body div.react-datepicker__input-container > input.envirio-datepicker {
  border: 2px solid rgba(0,0,0,0.25);
}

html > body h6 {
  margin: 0;
}

.inheritHeightDropdown > .dropdown {
  height: inherit !important;
}

.ui.text.menu .item {
  padding: 0;
}
